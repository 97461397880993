.container {
    position: relative;
    width: 1220px;
    height: 482px;
    margin: 80px auto 0;
}

.swiper_container {
    width: 100%;
    height: 486px;
    position: relative;
    padding: 0 93px;
}

.swiper-slide {
    width: 220px;
    height: 437px;
    position: relative;
}

.slider__arrow {
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 50%;
    width: 48px;
    height: 48px;
}

.slider-controler .slider-arrow {
    position: absolute;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    transform: translate(-10px, -50%);
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.slider-controler .swiper-button-prev {
    transform: translate(10px, -50%);
}

.slider-controler .slider-arrow::after {
    content: '';
}

.swiper-pagination {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 10px;
    width: auto !important;
    height: 20px !important;
}

.swiper-pagination .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: var(--color-blue);
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 20px;
}

.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
    display: none;
}

.background {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.slider__hover {
    position: absolute;
    width: 140px;
    height: 250px;
    right: 90px;
    top: 20%;
    background-color: white;
    opacity: .45;
    z-index: 1;
}

.slider__hover_left {
    left: 90px;
}

@media screen and (max-width: 1439px) {
    .container {
        width: 720px;
    }

    .swiper_container {
        padding: 0;
    }

    .slider__hover {
        width: 250px;
        height: 450px;
        right: 0;
        top: 0;
    }

    .slider__hover_left {
        left: 0;
    }
}

@media screen and (max-width: 767px) {
    .container {
        width: 100%;
        /* width: 320px; */
    }

    .slider__hover {
        width: 50px;
    }
}