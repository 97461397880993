.promo__lines {
    position: absolute;
    left: -25px;
    top: -40px;
}

.promo__container {
    width: 1160px;
    display: flex;
    margin: 0 auto 146px;
    justify-content: space-between;
}

.promo__image-and-text {
    display: flex;
    flex-direction: column;
    justify-content: start;
    position: relative;
}

.promo__button-text-blocks {
    display: flex;
}

.promo__text-title {
    margin-bottom: 7px;
}

.promo__text-block {
    position: absolute;
    text-align: left;
    width: 291px;
}

.promo__text-block1 {
    top: 5px;
    left: 32px;
}

.promo__text-block2 {
    bottom: 56px;
    left: 32px;
}

.promo__text-block3 {
    bottom: 56px;
    right: -154px;
}

.promo__text-block4 {
    top: 5px;
    right: -154px;
}

.promo__text-block5 {
    top: 172px;
    right: -154px;
}



@media screen and (max-width: 1439px) {
    .promo__container {
        flex-direction: column;
        width: 720px;
    }

    .promo__form {
        background-color: #FCF8F8;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
        padding: 32px 0;
    }

    .promo__text-block1 {
        top: 2px;
        left: 27px;
    }

    .promo__text-block2 {
        bottom: -70px;
        left: 27px;
    }

    .promo__text-block3 {
        bottom: -70px;
        right: 0px;
        width: 185px;
    }

    .promo__text-block4 {
        top: 2px;
        right: 0px;
        width: 185px;
    }

    .promo__text-block5 {
        top: 164px;
        right: 0px;
        width: 185px;
    }
}

@media screen and (max-width: 767px) {

    .promo__container {
        flex-direction: column;
        width: 328px;
        margin-bottom: 32px;
    }

    .promo__text-block,
    .promo__text-block1,
    .promo__text-block2,
    .promo__text-block3,
    .promo__text-block4,
    .promo__text-block5 {
        position: relative;
        right: 0;
        left: 0;
        top: 0;
    }

    .promo__ul {
        display: flex;
        align-items: flex-start;
    }

    .promo__li {
        width: 8px;
        height: 8px;
        margin-right: 16px;
        margin-top: 5px;
    }

    .promo__image-and-text {
        gap: 16px;
    }

    .promo__text-title {
        margin-bottom: 4px;
    }
}