.detail__close {
    cursor: pointer;
    position: absolute;
    border: none;
    background: none;
    outline: none;
    top: -24px;
    right: -24px;
}
.detail__close:hover {
    background: none;
}
.detail__overlay {
    cursor: pointer;
    outline: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: #000;
    opacity: .5;
    z-index: -1;
}
.detail__overlay:hover {
    background-color: #000;
}
.detail__container {
    width: 474px;
    padding: 32px;
    position: relative;
    background-color: white;
    border-radius: 20px;
}
.detail__list {
    list-style: none;
    margin: 0;
    margin-top: 24px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
    background: #FCF8F8;
}
.detail__price{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.detail__price_contract {
    padding: 24px;
}
.detail__text_bold {
    font-weight: 700;
}
.detail__text {
    text-align: left;
}
.detail__summary {
    font-size: 28px;
    font-weight: 900;
    line-height: 32px;
}
.detail__bottom {
    padding: 24px 24px 0;
    border-top: 1px solid #DBDBDB;
}
.detail__bottom-text {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}
.detail__line {
    height: 10px;
    border: none;
    border-bottom: 1px dotted #DBDBDB;
}
.detail__line_1 {
    width: 150px;
}
.detail__line_2 {
    width: 260px;
}
.detail__line_3 {
    width: 250px;
}
.detail__line_4 {
    width: 150px;
}
.detail__line_5 {
    width: 100px;
}
.detail__line_6 {
    width: 110px;
}
.detail__line_7 {
    width: 190px;
}
@media screen and (max-width: 767px) {
    .detail__line_1 {
        width: 50px;
    }
    .detail__line_2 {
        width: 60px;
    }
    .detail__line_3 {
        width: 50px;
    }
    .detail__line_4 {
        width: 50px;
    }
    .detail__line_5 {
        width: 10px;
    }
    .detail__line_6 {
        width: 20px;
    }
    .detail__line_7 {
        width: 90px;
    }
    .detail__container {
        width: 344px;
        padding: 24px;
        border-radius: 16px;
    }
    .detail__list {
        margin-top: 16px;
        padding: 16px;
        gap: 10px;
    }
    .detail__price_contract {
        padding: 16px;
    }
    .detail__bottom {
        padding: 16px 16px 0;
    }
    .detail__close {
        top: -44px;
        right: 0;
    }
}