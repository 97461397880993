.seller__container {
    width: 1160px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.seller__column {
    position: relative;
    width: 630px;
    text-align: left;
}
.seller__title {
    margin-top: 0;
}
.seller__subtitle {
    width: 605px;
    margin: 20px auto 40px;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
}
.seller__absolute {
    position: absolute;
}
.seller__absolute1 {
    top: 48px;
    left: 45px;
    width: 200px;
}
.seller__absolute2 {
    top: 48px;
    right: -7px;
    width: 200px;
}
.seller__absolute3 {
    bottom: 55px;
    left: 45px;
    width: 330px;
}
.seller__text-title {
    margin-bottom: 6px;
}
.seller__image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 90px 0;
    margin-left: 82px;
    margin-top: -4px;
}
@media screen and (max-width: 1439px) {
    .seller__image {
        padding: 44px 0;
        margin-bottom: 110px;
        margin-left: 0;
    }
    .seller__container {
        width: 100%;
        flex-direction: column;
    }
    .seller__column {
        width: 720px;
        margin: 0 auto;
    }
    .seller__title {
        text-align: center;
        width: 100%;
    }
    .seller__subtitle {
        text-align: center;
        margin: 0 auto;
        width: 670px;
        margin-top: 20px;
        font-size: 18px;
        line-height: 28px;
    }
    .seller__form {
        background-color: #FCF8F8;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        padding: 32px 0;
    }
    .seller__absolute1 {
        top: 43px;
        left: 24px;
        width: 200px;
    }
    .seller__absolute2 {
        top: 43px;
        right: 0;
        width: 200px;
    }
    .seller__absolute3 {
        bottom: 57px;
        left: 24px;
        width: 300px;
    }
}

@media screen and (max-width: 767px) {
    .seller__container {
        width: 100%;
        flex-direction: column;
        gap: 32px;
    }

    .seller__subtitle {
        margin: 0 auto;
        text-align: center;
        width: 328px;
        font-size: 16px;
        line-height: 24px;
    }

    .seller__image {
        width: 328px;
        margin: 0;
        padding: 16px 0;
    }

    .seller__absolute {
        position: relative;
    }

    .seller__column {
        width: 328px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .seller__title {
        width: 280px;
        margin: 0 auto;
    }

    .seller__absolute1,
    .seller__absolute2,
    .seller__absolute3 {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 304px;
    }

    .seller__text-text,
    .seller__text-title {
        font-size: 14px;
        line-height: 20px;
    }

    .seller__list {
        display: flex;
    }

    .seller__li {
        margin-top: 5px;
        width: 8px;
        height: 8px;
        margin-right: 16px;
    }

}