.economy {
    background-color: var(--color-blue);
}

.economy__container {
    width: 1160px;
    padding: 64px 0;
    margin: 0 auto;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.economy__column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.economy__title {
    color: var(--color-white);
    width: 570px;
}

.economy__text {
    font-size: 18px;
    margin-top: 20px;
    width: 450px;
    text-align: left;
    color: var(--color-white);
    margin-bottom: 40px;
}

.economy__table {
    display: flex;
    gap: 7px;
    color: var(--color-white);
    margin-bottom: 40px;
}

.economy__table-column {
    text-align: center;
    width: 300px;
}

.economy__table-column:last-child {
    width: 260px;
}

.economy__table-title {
    padding-top: 14px;
    padding-bottom: 16px;
    border: 1px solid var(--color-btn-file);
    border-radius: 10px 10px 0px 0px;
    background-color: var(--color-btn-file);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.economy__table-usd {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    padding-top: 20px;
    padding-bottom: 21px;
    color: var(--color-btn-file);
    border: 1px solid var(--color-white);
    background-color: var(--color-white);
    border-radius: 0px 0px 10px 10px;
}

.economy__image {
    margin-top: 40px;
    margin-right: 50px;
}

.economy__button {
    width: 278px;
    height: 39px;
    border-radius: 10px;
    border: none;
    background-color:var(--color-yellow);
    outline: none;
    cursor: pointer;
    color: var(--color-btn-file);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}


.economy__button:hover {
    color: var(--color-white);
    border: 1px solid #139DC8;
    background-color: #139DC8;
}

.economy__button:active {
    color: var(--color-white);
    background-color: #139DC8;
}

@media screen and (max-width: 1439px) {
 
    .economy__container {
        width: 720px;
        padding: 56px 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .economy__image {
        width: 352px;
        height: 352px;
        margin: 0;
        margin-bottom: 40px;
    }

    .economy__text {
        font-size: 16px;
        text-align: center;
        margin-bottom: 40px;
        margin-top: 20px;
        width: 100%;
    }
    
    .economy__subtitle {
        font-size: 28px;
        line-height: 32px;
        margin: 0;
        text-transform: uppercase;
        font-weight: 900;
    }

    .economy__title {
        text-align: center;
    }

    .economy__column {
        align-items: center;
    }

    .economy__table {
        gap: 16px;
        margin-bottom: 32px;
    }

    .economy__table-column, .economy__table-column:last-child {
        width: 352px;
    }
    
    .economy__table-usd_blue {
        color: var(--color-blue);
    }

    .economy__button {
        width: 210px;
        height: 40px;
        margin-top: 20px;
    }
}

@media screen and (max-width: 767px) {
    .economy__container {
        width: 328px;
        padding: 48px 0;
        text-align: center;
        flex-direction: column;
    }

    .economy__image {
        width: 328px;
        height: 328px;
        margin: 0;
        margin-bottom: 32px;
    }

    .economy__title {
        width: 315px;
    }

    .economy__text {
        width: 300px;
        margin: 16px auto 32px;
    }

    .economy__table {
        flex-direction: row;
        gap: 8px;
        justify-content: space-between;
    }

    .economy__table-column, .economy__table-column:last-child {
        width: 160px;
    }

    .economy__button {
        margin: 20px auto 0;
    }

    .economy__column {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .economy__table-usd {
        font-size: 18px;
        line-height: 24px;
        height: 56px;
        padding: 16px 0;
    }

    .economy__table-title {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 64px;
        font-size: 14px;
        line-height: 20px;
        padding: 0 16px;
    }
}
