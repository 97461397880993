.popup {
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility .3s, opacity .3s linear;
}

.popup_opened {
    visibility: visible;
    opacity: 1;
}
