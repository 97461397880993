.footer {
    margin-bottom: 80px;
}
.footer__two-column {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.footer__left-column {
    display: flex;
    height: 320px;
    flex-direction: column;
    justify-content: space-between;
}
.footer__container {
    width: 1160px;
    margin: 0 auto;
    text-align: left;
    margin-bottom: 80px;
}
.footer__block {
    width: 768px;
    height: 322px;
}
.footer__text {
    font-size: 18px;
    line-height: 28px;
    width: 376px;
}
.footer__number {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
}
.footer__mes  {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}
.footer__email {
    color: var(--color-blue);
}
.footer__button {
    text-decoration: none;
}
.footer__column {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.footer__link {
    width: 40px;
    height: 40px;
}
.footer__copyright-container {
    padding-top: 24px;
    width: 1160px;
    margin: 0 auto;
    text-align: center;
    border-top: 1px solid #DBDBDB;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.footer__copyright {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}
.footer__agreement-text-link {
    color: var(--color-blue);
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
}
.footer__link_phone {
    justify-content: flex-start;
}
@media screen and (max-width: 1439px) {
    .footer__container {
        width: 720px;
    }
    .footer__link_phone {
        justify-content: center;
    }
    .footer__left-column {
        height: auto;
        gap: 20px;
        align-items: center;
        margin-bottom: 40px;
    }
    .footer__two-column {
        flex-direction: column;
        align-items: center;
    }

    .footer__copyright-container {
        width: 720px;
    }

    .footer__column {
        margin: 0 auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .footer__text-span {
        width: 200px;
    }
    .footer__block {
        width: 720px;
    }
}

@media screen and (max-width: 767px) {
    .footer__container {
        width: 328px;
        text-align: center;
        padding: 47px auto;
        margin-bottom: 48px;
    }
    .footer__left-column {
        gap: 16px;
        margin-bottom: 32px;
    }
    .footer__copyright-container, .footer__text {
        width: 300px;
        text-align: center;
    }

    .footer__column {
        gap: 16px;
    }

    .footer__block {
        width: 328px;
        height: auto;
        margin-top: 10px;
    }
    .footer__email {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
    }
    .footer__title {
        margin-bottom: 0;
    }
    .footer__text, .footer__number {
        width: 328px;
        margin: 0;
        font-size: 16px;
        line-height: 24px;
    }
    .footer__copyright-container {
        width: 328px;
    }
    .footer__copyright {
        margin: 16px auto 16px;
        font-size: 12px;
        line-height: 16x;
    }
    
}