.exp__container {
    width: 1145px;
    margin: 0 auto;
}

.exp__subtitle {
    margin: 23px auto 90px;
    font-size: 18px;
    line-height: 28px;
}

.exp__grid-container {
    display: grid;
    list-style: none;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 60px;
    column-gap: 43px;
}

.exp__grid-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.exp__text {
    margin-top: 19px;
    text-align: left;
}

.exp__bottom-title {
    font-weight: 700;
    margin: 120px auto 48px;
    color: var(--color-blue);
}

.bottom__container {
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

@media screen and (max-width: 1439px) {
    .exp__container {
        width: 940px;
    }

    .exp__grid-container {
        grid-template-columns: repeat(4, 1fr);
        row-gap: 24px;
        column-gap: 22px;
        margin: 0;
    }

    .exp__subtitle {
        margin: 23px auto 42px;
        font-size: 18px;
        line-height: 22px
    }

    .exp__text {
        margin-left: 16px;
    }

    .exp__bottom-title {
        font-weight: 800;
        margin: 80px auto 20px;
        color: var(--color-black);
    }
    
}

@media screen and (max-width: 1000px) {
    .exp__container {
        width: 720px;
    }

    .exp__grid-container {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 80x;
        column-gap: 22px;
        margin: 0;
    }

    .exp__grid-item {
        flex-direction: row;
    }
}

@media screen and (max-width: 767px) {
    .exp__container {
        width: 328px;
        margin: 40px auto;
    }

    .exp__grid-container {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 20x;
        column-gap: 8px;
        margin: 0;
    }

    .exp__subtitle {
        margin: 16px auto;
        font-size: 16px;
        line-height: 24px
    }

    .exp__text {
        margin-top: 16px;
        width: 140px;
        text-align: center;
    }

    .exp__grid-item {
        align-items: center;
    }

    .exp__bottom-title {
        margin: 48px auto 20px;
    }

    .exp__grid-item {
        flex-direction: column;
    }

    .exp__text {
        margin-left: 0;
    }
    
}