.second__line {
    position: absolute;
}
.second__line1 {
    top: 54px;
    left: 21px;
}
.second__line2 {
    top: 54px;
    left: 315px;
}
.second__line3 {
    bottom: 33px;
    left: 21px;
}
.second__line0{
    margin-top: 50px;
}
@media screen and (max-width: 1439px) {
    .second__line0{
        margin-top: 90px;
    }
    .second__line1 {
        top: 49px;
        left: 0;
    }
    .second__line2 {
        top: 49px;
        left: 344px;
    }
    .second__line3 {
        bottom: 35px;
        left: 0;
    }
}

@media screen and (max-width: 767px) {
    .second__line0 {
        margin: 0;
    }
}