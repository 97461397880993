.button {
    min-width: 160px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding-right: 20px;
    padding-left: 20px;
}

.button_primary {
    background-color: var(--color-yellow);
}

.button_disabled {
    background-color: #cdcdcd;
}