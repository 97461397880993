.doubts__header {
    background-color: #FCF8F8;
    padding: 64px 0 59px;
}

.doubts__header-title {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 24px;
}

.doubts__header-subtitle {
    width: 1060px;
    margin: 24px auto 32px;
}

.doubts__container {
    width: 1160px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.doubts__column {
    position: relative;
}

.doubts__absolute-text {
    text-align: left;
    position: absolute;
}

.doubts__column .doubts__absolute-text:nth-child(3) {
    top: 130px;
    width: 200px;
    left: 0;
}

.doubts__column .doubts__absolute-text:nth-child(4) {
    top: 130px;
    width: 205px;
    right: -166px;
}

.doubts__column .doubts__absolute-text:nth-child(5) {
    bottom: 40px;
    width: 332px;
    left: 0;
}

.doubts-bottom__container {
    margin: 0 auto;
    width: 300px;
    display: flex;
    justify-content: center;
}

.doubts__image {
    margin-top: 40px;
}

@media screen and (max-width: 1439px) {
    .doubts__container, .doubts__header-subtitle, .doubts-title__header-title {
        width: 720px;
        margin: 0 auto;
    }

    .doubts__container {
        margin: 0 auto;
        width:720px;
        gap: 20px;
    }

    .doubts__header-subtitle {
        margin: 20px auto;
        font-size: 18px;
        line-height: 28px;
    }
    

    .doubts__image {
        margin: 0;
        position: absolute;
        width: 382px;
        height: 344px;
        left: -2px;
        top: 256px;
    }

    .doubts__column .doubts__absolute-text:nth-child(3) {
        top: 183px;
        width: 200px;
        left: -2px;
    }
    
    .doubts__column .doubts__absolute-text:nth-child(4) {
        top: 123px;
        width: 205px;
        right: -59px;
    }
    
    .doubts__column .doubts__absolute-text:nth-child(5) {
        bottom: 9px;
        width: 332px;
        left: -2px;
    }

    .doubts__header {
        padding: 80px 0;
    }
}

@media screen and (max-width: 767px) {
    .doubts__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 48px 0;
    }

    .doubts {
        padding: 46px 0 62px;
    }

    .doubts__container, .doubts__header-subtitle, .doubts-title__header-title {
        margin: 0 auto;
        width: 300px;
    }

    .doubts__container {
        gap: 36px;
        flex-direction: column;
    }

    .doubts__header-subtitle {
        margin: 16px auto 24px;
        font-size: 16px;
        line-height: 24px;
    }

    .doubts__header-title {
        margin: 0;
        margin-bottom: 0;
    }

    .doubts__absolute-text, .doubts__image {
        position: relative;
        top: 0;
        left: 0;
        margin: 0;
    }

    .doubts__image {
        width: 169px;
        height: 234px;
        margin: 36px auto;
    }


    .doubts__column .doubts__absolute-text:nth-child(3) {
        top: 0;
        left: 0;
        width: 300px;
        margin-top: 20px;
    }
    
    .doubts__column .doubts__absolute-text:nth-child(4) {
        width: 300px;
        top: 0;
        left: 0;
    }
    
    .doubts__column .doubts__absolute-text:nth-child(5) {
        width: 300px;
        top: 0;
        left: 0;
    }
}