.found {
    margin-bottom: 80px;
}
.found__container {
    width: 1160px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.found__column_one {
    position: relative;
    text-align: left;
}
.found__title {
    margin: 0 auto 40px;
    width: 964px;
}
.found__subtitle {
    width: 605px;
    margin-top: 16px;
    margin-bottom: 16px;
}
.found__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}
.found__absolute {
    position: absolute;
}
.found__yourself_image {
    margin-top: 20px;
    margin-right: 150px;
}
.found__absolute-text {
    margin-top: 6px;
}
.found__column_one .found__absolute:nth-child(2) {
    width: 235px;
    top: 24px;
    left: 35px;
}
.found__column_one .found__absolute:nth-child(3) {
    width: 279px;
    top: 27px;
    right: -115px;
}
.found__column_one .found__absolute:nth-child(4) {
    width: 358px;
    bottom: 47px;
    left: 35px;
}
.found__absolute-title  {
    width: 218px;
}
@media screen and (max-width: 1439px) {
    .found__container {
        width: 100%;
        gap: 20px;
        flex-direction: column;
    }
    .found__column {
        background-color: #FCF8F8;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
        padding: 32px 0;
    }
    .found__column_one {
        width: 720px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 160px;
    }
    .found__title {
        width: 720px;
        margin: 0 auto 36px;
    }
    .found__yourself_image {
        margin-top: 132px;
    }
    .found__absolute-title1 {
        width: 318px;
    }
    .found__column_one .found__absolute:nth-child(2) {
        width: 305px;
        top: 7px;
        left: 24px;
    }
    .found__column_one .found__absolute:nth-child(3) {
        width: 250px;
        top: 7px;
        right: 40px;
    }
    .found__column_one .found__absolute:nth-child(4) {
        width: 248px;
        bottom: 46px;
        left: 24px;
    }
}

@media screen and (max-width: 767px) {
    .found {
        margin-bottom: 48px;
    }
    
    .found__container {
        width: 100%;
        gap: 41px;
        flex-direction: column;
    }

    .found__column_one {
        flex-direction: column;
        width: 328px;
        align-items: flex-start;
        padding: 0;
        gap: 20px;
    }

    .found__title {
        width: 300px;
        margin: 0 auto 32px;
    }

    .found__absolute {
        position: relative;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }
    
    .found__column_one .found__absolute:nth-child(1) {
        width: 280px;
        top: 0;
        left: 0;
        margin-bottom: 0;
    }
    
    .found__column_one .found__absolute:nth-child(2) {
        width: 280px;
        top: 0;
        left: 0;
        margin-bottom: 0;
    }
    
    .found__column_one .found__absolute:nth-child(4) {
        width: 280px;
        top: 0;
        left: 0;
    }

    .found__yourself_image {
        margin: 0;
    }

    .found__list {
        display: flex;
    }
    .found__absolute-title  {
        width: 280px;
        font-size: 14px;
        line-height: 22px;
    }
    .found__absolute-text  {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
    }
}