.spinner__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.61);
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner__overlay_inactive {
    display: none;
}

.spinner__logo {
    position: absolute;
    left: calc(50% - 30px);
    top: calc(50% - 10px);
    width: 60px;
    height: 20px;
}

.spinner {
    width: 76px;
    height: 76px;
    border-radius: 50%;
    background: conic-gradient(#0000 10%,#2fbae5);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 5px),#000 0);
    animation: spinner 0.8s infinite linear;
    display: flex;
    align-items: center;
    justify-content: center;
 }
 
 @keyframes spinner {
    to {
       transform: rotate(1turn);
    }
 }