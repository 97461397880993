.variants__container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 1160px;
}

.variants__bottom-text {
    margin-top: 40px;
    width: 824px;
    align-self: center;
}

.variants__card-title {
    text-align: center;
    width: 100%;
    padding: 24px;
    background: var(--color-btn-file);
    color: var(--color-white);
    border-radius: 10px 10px 0px 0px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

.variants__card-bottom {
    text-align: center;
    width: 100%;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin-top: 10px;
}

.variants__card-paragraphs {
    padding: 24px;
    height: 324px;
}

.variants__title {
    color: var(--color-btn-file);
    margin-bottom: 40px;
}

.variants__cards {
    display: flex;
    justify-content: space-between;
}

.variants__card {
    position: relative;
    text-align: left;
    width: 572px;
    height: 539px;
    border-radius: 10px;
    border: 1px solid #DEDEDE;
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
}

.variants__card-text {
    margin-bottom: 17px;
    display: flex;
    gap: 16px;
}

.variants__card-text:last-child {
    margin-bottom: 0;
}



.variants__card-bottom-text {
    border-top: 1px solid #DEDEDE;
    padding-top: 24px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

@media screen and (max-width: 1439px) {
    .variants__title {
        margin-bottom: 40px;
    }

    .variants__container {
        width: 720px;
    }
    
    .variants__card {
        height: 768px;
        width: 352px;
    }

    .variants__card-paragraphs {
        margin: 0;
        padding: 24px;
        height: 540px;
    }

    .variants__card-title {
        width: 100%;
        height: 104px;
        padding: 16px 24px;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
    }
    
    .variants__card-text {
        margin-bottom: 0;
        align-items: flex-start;
        margin-bottom: 20px;
    }
    
    .variants__text {
        line-height: 24px;
    }

    .variants__card-bottom-text {
        font-size: 16px;
        line-height: 20px;
        padding-top: 20px;
    }

    .variants__card-bottom {
        padding: 0 24px;
        width: 100%;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        margin-top: 8px;
    }

    .variants__bottom-text {
        margin-top: 40px;
        width: 720px;
    }

}

@media screen and (max-width: 767px) {
    .variants__container {
        width: 328px;
    }
    
    .variants__cards {
        flex-direction: column;
        gap: 20px;
    }
    
    .variants__title {
        margin: 0 auto 32px;
    }
    
    .variants__card {
        padding: 0;
        height: auto;
        width: 328px;
    }
    
    .variants__card-title {
        font-size: 18px;
        margin-bottom: 0;
        width: 100%;
        position: relative;
    }

    .variants__card-title_font {
        font-size: 16px;
        line-height: 20px;
    }

    .variants__card-text {
        margin-bottom: 0;
    }

    .variants__text {
        font-size: 14px;
    }
    .variants__card-paragraphs {
        padding: 24px 16px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
    }
    
    .variants__card-bottom-text {
        font-size: 14px;
        line-height: 20px;
    }

    .variants__card-bottom {
        padding-bottom: 24px;
        width: 328px;
        font-size: 18px;
        margin-top: 8px;
    }

    .variants__bottom-text {
        margin-top: 32px;
        width: 320px;
        font-size: 14px;
        line-height: 20px;
    }
}