.notfound__container {
    margin: 23px auto 0;
}

.notfound__title {
    margin: 11px auto 36px;
    font-weight: 500;
    font-size: 32px;
    line-height: 165%;
}

.notfound__button {
    margin: 0 auto;
    width: 337px;
    height: 64px;
    border-radius: 5px;
    text-decoration: none;
    border: none;
    background-color: var(--color-blue);
    color: var(--color-white);
}

.notfound__button-text {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}

.send__text {
    width: 550px;
    text-align: left;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}

.send__container {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 1375px;
    margin: 0 auto;
}

.notfound__title_send {
    margin-left: 0;
    margin-top: 28px;
    margin-bottom: 15px;
}

.notfound__button_send {
    margin-top: 48px;
    margin-left: 0;
    margin-bottom: 66px;
}

.policy__title {
    text-align: left;
    font-weight: 600;
    font-size: 40px;
    line-height: 165%;
}

.policy__container {
    width: 1375px;
    margin: 60px auto 155px;
}

.policy__text {
    text-align: left;
    font-weight: 500;
    font-size: 25px;
    line-height: 150%;
    margin-top: 24px;
}

@media screen and (max-width: 1430px) {
    .policy__container {
        width: 715px;
        margin-bottom: 60px;
    }

    .policy__text {
        font-size: 18px;
        line-height: 165%;
        margin-top: 20px;
        margin-bottom: 4px;
    }

    .notfound__title {
        font-weight: 600;
        font-size: 25px;
        line-height: 165%;
    }

    .notfound__title_send {
        margin-top: -53px;
        margin-bottom: 17px;
    }

    .send__text {
        font-weight: 500;
        font-size: 18px;
        line-height: 165%;
    }

    .notfound__image {
        width: 274px;
        height: 206px;
    }

    .notfound__button {
        width: 353px;
    }

    .send__container {
        width: 720px;
    }

    .notfound__button_send {
        margin-top: 35px;
        margin-bottom: 22px;
    }

}

@media screen and (max-width: 768px) {
    .policy__container {
        width: 328px;
        margin-bottom: 30px;
    }

    .policy__text {
        margin-top: 16px;
    }

    .policy__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 165%;
        margin-bottom: 11px;
    }
}