.not-sellers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.not-sellers__title {
    color: var(--color-blue)
}

.not-sellers__subtitle {
    width: 819px;
    margin-top: 16px;
    margin-bottom: 44px;
}

.not-sellers__card {
    width: 278px;
    padding: 29px 26px 23px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: left;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
}

.not-sellers__photo {
    width: 100px;
    height: 100px;
}

.not-sellers__card-title {
    margin-top: 36px;
    margin-bottom: 12px;
}

.not-sellers__cards {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
}

@media screen and (max-width: 1439px) {
    .not-sellers__cards {
        grid-template-columns: repeat(2, 1fr);
    }

    .not-sellers__title {
        color: var(--color-black);
    }

    .not-sellers__subtitle {
        width: 720px;
    }

    .not-sellers {
        margin-top: 80px;
    }
}

@media screen and (max-width: 767px) {
    .not-sellers__subtitle {
        width: 300px;
        margin-top: 16px;
        margin-bottom: 24px;
    }

    .not-sellers__cards {
        grid-template-columns: repeat(1, 1fr);
    }

    .not-sellers {
        margin-top: 48px;
    }
}