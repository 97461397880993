.feedbackPopup {
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility .3s, opacity .3s linear;
    z-index: 5;
}
.feedbackPopup_opened {
    visibility: visible;
    opacity: 1;
}
.feedbackPopup__overlay {
    cursor: pointer;
    outline: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: #000;
    opacity: .5;
    z-index: -1;
}
.feedbackPopup__overlay:hover {
    background-color: #000;
}
.feedbackPopup__container {
    position: relative;
    width: auto;
}
.feedbackPopup__close {
    cursor: pointer;
    position: absolute;
    border: none;
    background: none;
    outline: none;
}
.feedbackPopup__close:hover {
    background: none;
}
.feedbackPopup__image {
    width: 440px;
    height: 874px;
}

@media screen and (max-width: 767px) {
    .feedbackPopup__image {
        width: 70%;
        height: auto;
    }
    .feedbackPopup__close {
        right: 55px;
        top: -20px;
    }
}