.manager__container {
    width: 1160px;
    margin: 0 auto;
}

.manager__button-container {
    width: 160px;
    margin: 0 auto;
    align-self: center;
}

.manager__title {
    margin: 0 auto;
}

.manager__button-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.manager__photo {
    width: 120px;
    height: 120px;
    border-radius: 60px;
}

.manager__about {
    text-align: left;
    margin-left: 24px;
}

.manager__works {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
}

.manager__work {
    display: flex;
    align-items: flex-start;
}

.manager__text {
    text-align: left;
    margin-left: 22px;
}

.manager__desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 48px;
}

.manager__managers {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    margin: 40px auto;
}

.manager__man {
    list-style: none;
    display: flex;
    align-items: center;
    width: 376px;
    height: 184px;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 36px;
}

.manager__country {
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.manager__job {
    margin: 16px auto 8px;
}

.manager__li {
    margin-top: 6px;
    height: 8px;
    width: 8px;
}




@media screen and (max-width: 1439px) {
    .manager__container {
        width: 720px;
    }

    .manager__text {
        margin-left: 16px;
        font-size: 16px;
        line-height: 24px;
        width: 328px;
    }

    .manager__works {
        margin-right: 16px;
        gap: 24px;
    }

    .manager__works:last-child {
        margin-right: 0;
    }

    .manager__managers {
        margin: 40px auto;
        width: 720px;
    }

    .manager__man {
        width: 352px;
        height: 140px;
        border-radius: 12px;
        padding: 24px;
    }

    .manager__photo {
        width: 92px;
        height: 92px;
        border-radius: 46px;
    }

    .manager__country {
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
    }

    .manager__name {
        line-height: 22px;
    }
}

@media screen and (max-width: 767px) {
    .manager__container {
        width: 328px;
    }

    .manager {
        width: 100%;
        margin:  0 auto 48px;
    }

    .manager__title {
        width: 300px;
    }

    .manager__man {
        width: 328px;
        height: 116px;
        padding: 20px;
    }

    .manager__managers{
        width: 328px;
        margin: 32px auto;
    }

    .manager__photo {
        width: 76px;
        height: 76px;
    }

    ul {
        margin-right: 24px;
    }

    .manager__desc {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        gap: 20px;
    }

    .manager__works {
        height: auto;
        margin-right: 0;
        gap: 20px;
    }

    .manager__text {
        width: 222px;
        font-size: 14px;
        line-height: 20px;
    }

    .manager__country {
        font-size: 12px;
        line-height: 16px;
    }

    .manager__job {
        font-size: 14px;
        line-height: 20px;
        margin: 4px auto 16px;
    }

    .manager__name {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
    }

}