.line {
    position: absolute;
}

.line0 {
    margin-top: 107px;
    margin-left: 22px;
}

.line1 {
    top: 11px;
    left: -3px;
}

.line2 {
    top: 11px;
    left: 208px;
}

.line3 {
    top: 179px;
    left: 294px;
}

.line4 {
    top: 404px;
    left: 323px;
}

.line5 {
    top: 324px;
    left: -3px;
}

@media screen and (max-width: 1439px) {
    .line0 {
        margin: auto;
        margin-top: 90px;
        width: 536px;
        height: 398px;
        object-fit: cover;
    }
    
    .line1 {
        top: 8px;
        left: 3px;
    }
    
    .line2 {
        top: 8px;
        right: 103px;
    }
    
    .line3 {
        top: 170px;
        right: 127px;
    }
    
    .line4 {
        top: 363px;
        right: 175px;
    }
    
    .line5 {
        top: 350px;
        left: 3px;
    }
}

@media screen and (max-width: 767px) {
    .line0 {
        margin: 0;
        width: 328px;
        height: 239px;
        margin-bottom: 16px;
    }
}