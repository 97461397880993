.form {
    background-color: white;
    border-radius: 20px;
}
.button {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: var(--color-yellow);
    color: #000;
}
.react-dadata__input {
    padding-left: 15px;
    width: 100%;
    height: 40px;
    background-color: var(--color-white);
    color: #232323;
    border: 1px solid var(--color-underline);
    border-radius: 10px;
    outline: none;
    position: relative;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}
.react-dadata__input:focus {
    box-shadow: none;
    border: 1px solid var(--color-black);
    outline: none;
}
.form__input {
    position: relative;
}
.react-dadata__suggestions {
    position: absolute;
    top: 40px;
}
.form__input-phone {
    padding-left: 18px !important;
    width: 100% !important;
    height: 40px !important;
    background-color: var(--color-white) !important;
    color: #232323 !important;
    border: 1px solid var(--color-underline) !important;
    border-radius: 10px !important;
    outline: none !important;
    font-family: 'Montserrat' !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 17px !important;
}
.form__input-phone-button {
    display: none !important;
}
.input__container {
    width: 100%;
    margin: 32px auto 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: white;
}
.form__container {
    padding: 32px;
    width: 465px;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form__title {
    width: 100%;
    margin: 0 auto 16px;
}
.form__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    width: 387px;
}
.form__text {
    align-self: start;
    width: 100%;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}
.form__text_agreement {
    text-align: center;
    width: 100%;
    margin-top: 32px;
}
.form__agreement-text-link {
    text-decoration: none;
    color: var(--color-yellow);
}
.form__textarea {
    width: 100%;
    color: #232323;
    border: 1px solid var(--color-underline);
    border-radius: 10px;
    outline: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding: 15px;
    font-family: "Montserrat";
    resize: none;
}
.form__add-file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.button__file {
    position: relative;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 32px;
    background-color: var(--color-btn-file);
    color: #FFFFFF;
    margin-top: 16px;
}
.form__add-file-input {
    cursor: pointer;
}
.form__selected {
    display: flex;
    /* flex-wrap: wrap; */
}
.form__added {
    display: none;
    width: 100%;
}
.form__added_active {
    display: block;
    margin: 17px auto;
}
.form__added-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    width: 100%;
}
.form__delete-file {
    margin-left: 10px;
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
}
.form__delete-file:hover {
    background: none;
}
.form__size_active {
    margin: 17px auto 17px;
    display: block;
    color: red;
}
.form__size {
    display: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    width: 230px;
    align-self: flex-start;
    text-align: left;
}
.form__button-text {
    font-size: 14px;
    line-height: 20px;
    color: var(--color-black);
}
.button__file_tablet {
    background-color: white;
}
.button__file_tablet:hover {
    background-color: white;
}

@media screen and (max-width: 1439px) {
    .form {
        margin: 0 auto;
    }
    .form__container {
        width: 720px;
        border-radius: 8px;
    }
    .react-dadata__input {
        width: 100%;
        margin: 0;
    }
    .form__textarea {
        width: 100%;
    }
    .form__input-phone {
        width: 100% !important;
    }
    .form__text_agreement {
        width: 100%;
    }
    .input__container {
        /* margin: 30px auto 16px; */
        gap: 16px;
    }
    .form__subtitle {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
    }
    .form__title {
        text-align: center;
        width: 100%;
        margin-bottom: 16px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 800;
    }
    .form__button-text_tablet {
        margin-left: 10px;
        color: var(--color-black);
    }
    .button__file {
        margin-bottom: 31px;
    }
    .form__text_agreement {
        text-align: center;
        font-size: 12px;
        line-height: 16px;
    }
}

@media screen and (max-width: 767px) {
    .form__container {
        width: 328px;
        padding: 32px 20px;
    }

    .form__text_agreement {
        width: 257px;
        margin-top: 24px;
    }

    .input__container {
        margin: 24px auto 16px;
        gap: 16px;
    }

    .form__subtitle {
        width: 257px;
        font-size: 14px;
        line-height: 17px;
    }

    .form__title {
        width: 288px;
        margin: 0;
        margin-bottom: 16px;
        font-size: 18px;
    }

    .form__text {
        width: 257px;
    }

    .form__added-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        max-width: 220px;
    }
    
    .button__file {
        margin-top: 0;
        margin-bottom: 24px;
    }

}