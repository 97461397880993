.feedback {
    margin-top: 120px;
}

.feedback__container {
    width: 1290px;
    margin: 0 auto;
}

.feedback__subtitle  {
    margin: 24px auto 0;
    width: 1187px;
    text-align: left;
}

.feedback__garanty {
    margin: 40px auto 0;
    width: 1160px;
    display: flex;
    justify-content: space-between;
}

.feedback__garanty-item {
    width: 376px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.feedback__garanty-item-title {
    width: 350px;
    margin-top: 16px;
    text-align: center;
}

.feedback__slider {
    position: relative;
    width: 1220px;
    height: 482px;
    margin: 80px auto 0;
}

.feedback__slider-image {
    cursor: pointer;
    width: 223px;
    height: 462px;
    align-self: center;
    object-fit: contain;
}

.feedback__overlay {
    position: absolute;
    top: 0;
    width: 480px;
    height: 500px;
    opacity: .3;
    background-color:white;
    z-index: 0;
}

.feedback__overlay_right {
    right: 0;
    width: 500px;
}

@media screen and (max-width: 1439px) {
    .feedback {
        margin-top: 80px;
    }

    .feedback__container {
        width: 720px;
    }

    .feedback__subtitle  {
        text-align: center;
        width: 720px;
        font-size: 18px;
        line-height: 26px;
        margin-top: 20px;
    }

    .feedback__slider {
        width: 720px;
        height: 482px;
        margin: 20px auto 0;
    }

    .feedback__overlay {
        width: 240px;
    }

    .feedback__garanty {
        margin: 40px auto 0;
        width: 720px;
    }

    .feedback__garanty-item {
        width: 230px;
    }
    
    .feedback__garanty-item-title {
        width: 100%;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }
    
}

@media screen and (max-width: 767px) {
    .feedback__container {
        width: 100%;
    }
    
    .feedback {
        margin-top: 80px;
    }

    .feedback__title  {
        margin: 0 auto;
        width: 328px;
    }

    .feedback__subtitle {
        width: 316px;
        font-size: 14px;
        line-height: 20x;
    }
    
    .feedback__slider {
        width: 100%;
        margin-bottom: 100px;
    }

    .feedback__garanty {
        margin: 32px auto 0;
        width: 328px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .feedback__overlay {
       display: none;
    }

    .feedback__garanty-item {
        width: 100%;
    }
    

    .feedback__garanty-item-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
    }

}