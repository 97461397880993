.title__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title__title {
    margin: -4px auto 48px;
}

.title__sale {
    padding-top: 8px;
    margin-top: 4px;
    display: flex;
    justify-content: center;
    width: 509px;
    height: 50px;
}

.title__sale-text {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: var(--color-blue);
}

.title__subtitle, .title__title, .title__countries, .title__sale-text, .title__sale, .title__container {
    overflow: hidden;
}


@media screen and (max-width: 1439px) {
    .title__title {
        margin-top: 0;
    }

    .title__countries {
        width: 500px;
    }
}

@media screen and (max-width: 1200px) {
    .title__container {
        width: 720px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 767px) {
    .title__container {
        width: 328px;
        margin: 0 auto;
    }

    .title__title {
        margin: -4px auto 32px;
    }

    .title__countries {
        width: 328px;
       font-size: 20px;
       line-height: 24px;
    }
}