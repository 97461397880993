@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --color-grey: #797979;
    --color-underline: #D9D9D9;
    --color-black: #4D4D4D;
    --color-blue: #2FBAE5;
    --color-white: #FFFFFF;
    --color-yellow: #FFD24D;
    --color-btn-hover: #FFC005;
    --color-btn-disabled: #757575;
    --color-btn-file: #4C4C4C;
}

section {
    margin-bottom: 80px;
}

h1 {
    font-weight: 900;
    font-size: 100px;
    line-height: 122px;
    text-transform: uppercase;
    color: #4C4C4C;
}

h2 {
    font-weight: 900;
    font-size: 36px;
    line-height: 40px;
    text-transform: uppercase;
    color: var(--color-black);
}

h3 {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: var(--color-black);
}

h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: var(--color-black);
}

h5 {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: var(--color-black);
}

h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-black);
}

p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

body, button {
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button:hover {
    background-color: #FFC005;
}

@media screen and (max-width: 1439px) {
    section {
        margin-bottom: 80px;
    }

    h2 {
        font-weight: 900;
        font-size: 28px;
        line-height: 32px;
        text-transform: uppercase;
        color: var(--color-black);
    }
    
    h3 {
        font-size: 26px;
        line-height: 29px;
    }
    
    h4 {
        font-size: 18px;
    }
    
    h5 {
        line-height: 32px;
    }
    p {
        font-size: 16px;
    }
}

@media screen and (max-width: 1200px) {
    h1 {
        font-size: 64px;
        line-height: 78px;
    }
}

@media screen and (max-width: 767px) {
    h1 {
        font-size: 30px;
        line-height: 32px;
    }
    
    h2 {
        font-size: 20px;
        line-height: 24px;
    }
    
    h3 {
        font-size: 18px;
        line-height: 24px;
    }
    
    h5 {
        font-size: 16px;
        line-height: 24px;
    }
    
    h6 {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: var(--color-black);
    }
    
    p {
        font-size: 14px;
        line-height: 20px;
    }

    header {
        margin-top: 16px;
    }

    section {
        margin-bottom: 48px;
    }
    
}