.individual__item {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 24px 32px;
    width: 964px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.individual__image {
    width: 540px;
    height: 360px;
}

.individual__row {
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.individual__col-product-bg {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--color-btn-file);
    border-radius: 5px;
    max-width: 393px;
    height: auto;
    padding: 8px;
    margin-top: 34px;
    margin-bottom: 24px;
}

.individual__col-product {
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
    margin-bottom: 12px;
}

.individual__col-texts {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 344px;
}

.individual__col-text {
    line-height: 24px;
}

.individual__column {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.individual__subtitle {
    margin: 16px auto 44px;
}

.individual__slider {
    margin: 0 auto;
}

.individual__container {
    width: 1218px;
    margin: 0 auto;
}

.slick-arrow {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    background-repeat: no-repeat !important;
    width: 48px !important;
    height: 48px !important;
    border-radius: 50% !important;
}

.slick-slide {
    margin: 0;
    max-width: 100%;
    min-height: 1px;
}

.slick-prev::before,
.slick-next::before {
    display: none;
    content: none;
}

.individual__button {
    cursor: pointer;
    outline: none;
    border: none;
    background: none;
    color: var(--color-blue);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

.individual__button-close {
    cursor: pointer;
    outline: none;
    border: none;
    background: none;
    color: var(--color-blue);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    align-self: flex-start;
}

.individual__button:hover,
.individual__button-close:hover {
    background: none;
}

.individual__button_hidden {
    display: none;
}

.individual__row_opened {
    padding-top: 24px;
    border-top: 1px solid #DBDBDB;
}

.individual__col-subtitle {
    width: 344px;
}

@media screen and (max-width: 1439px) {
    .individual__container {
        width: 748px;
        margin: 0 auto;
    }

    .slick-slide {
        margin: 0;
        max-width: 100%;
        min-height: 1px;
    }

    .individual__item {
        border-radius: 12px;
        padding: 24px;
        width: 720px;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 20px;
        margin-top: 20px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    }

    .individual__image {
        width: 328px;
        height: 264px;
        border-radius: 12px;
        object-fit: cover;
    }

    .individual__column {
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 328px;
    }

    .individual__column_bottom {
        justify-content: flex-start;
        gap: 8px;
    }

    .individual__col-product {
        margin-bottom: 16px;
        color: var(--color-black);
    }

    .individual__subtitle {
        margin: 20px auto 40px;
        font-size: 18px;
        line-height: 28px;
    }
}

@media screen and (max-width: 767px) {
    .individual__container {
        width: 100%;
    }

    .individual__column {
        width: 288px;
    }

    .individual__row {
        flex-direction: column-reverse;
        gap: 20px;
        justify-content: space-between;
    }

    .individual__item {
        border-radius: 12px;
        padding: 20px;
        width: 328px;
    }

    .slick-slide {
        margin: 0;
        max-width: 100%;
        /* height: auto; */
        min-height: 1px;
    }

    .individual__col-subtitle {
        width: auto;
    }

    .individual__image {
        width: 288px;
        height: 216px;
    }

    .individual__col-product {
        font-size: 18px;
    }

    .individual__button {
        margin-top: 20px;
    }

    .individual__subtitle {
        margin: 16px auto 32px;
        font-size: 16px;
        line-height: 24px;
    }

    .individual__col-text {
        font-size: 14px;
        line-height: 20px;
    }

    .individual__col-texts {
        width: 280px;
    }

    .individual__title {
        width: 328px;
        margin: 0 auto;
    }
}