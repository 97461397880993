.popup-form {
    background-color: white;
    border-radius: 20px;
}

.popup-form__close {
    cursor: pointer;
    position: absolute;
    border: none;
    background: none;
    outline: none;
    top: -24px;
    right: -24px;
}

.popup-form__close:hover {
    background: none;
}

.popup-form__overlay {
    cursor: pointer;
    outline: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: #000;
    opacity: .5;
    z-index: -1;
}

.popup-form__overlay:hover {
    background-color: #000;
}

.popup-form__container {
    position: relative;
    padding: 24px 27px;
    width: 465px;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popup-form__title {
    width: 100%;
    margin: 0 auto 16px;
    margin-left: 10px;  
}

.popup-form__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    width: 387px;
    margin-bottom: 32px;
}

.popup-form__input {
    width: 100%;
    position: relative;
}

.popup-form__input-phone {
    margin: 16px 0 32px;
    width: 100%;
}

@media screen and (max-width: 767px) {
    .popup-form__container {
        width: 328px;
    }
    .popup-form__subtitle {
        font-size: 14px;
        width: 300px;
        margin-bottom: 24px;
    }
    .popup-form__input-phone {
        margin-bottom: 24px;
    }
    .popup-form__close {
        top: -44px;
        right: 0;
    }
}
