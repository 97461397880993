.third__line {
    position: absolute;
}

.third__line0 {
    margin-top: 95px;
    margin-left: -1px;
}

.third__line1 {
    top: 32px;
    left: 0;
}

.third__line2 {
    top: 34px;
    right: 54px;
}

.third__line3 {
    bottom: 27px;
    left: -1px;
}

@media screen and (max-width: 1439px) {
    .third__line0{
        margin-top: 120px;
    }

    .third__line1 {
        top: 13px;
    }
    
    .third__line2 {
        top: 13px;
        left: 368px;
    }
    
    .third__line3 {
        bottom: 18px;
        left: 0px;
    }
}

@media screen and (max-width: 767px) {
    .third__line0{
        margin-top: 0;
        margin-bottom: 12px;
    }
}