.header__container {
    width: 1160px;
    margin: 20px auto 68px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-bottom: 1px solid var(--color-underline);
    padding-bottom: 24px;
}

.header__logo {
    width: 186px;
    height: 40px;
}

.header__modal {
    width: 181px;
    position: absolute;
    right: 0;
    top: 44px;
    padding: 20px 24px;
    background-color: var(--color-blue);
}

.header__link {
    text-decoration: none;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: var(--color-black);
}

.header__tg-color:hover {
    fill: #028FD3;
}

.header__wa-color:hover {
    fill: #18C13A;
}

.header__link_modal {
    color: var(--color-white);
    font-size: 18px;
    line-height: 165%;
}

.header__contacts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.header__button {
    width: 170px;
}

.header__button-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.header__mes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}

@media screen and (max-width: 1439px) {
    .header {
        padding: 0 20px;
    }

    .header__container {
       width: 100%;
    }
}


@media screen and (max-width: 767px) {
    .header {
        padding: 0;
    }
    .header__container {
        width: 328px;
        flex-direction: column;
        padding-bottom: 16px;
        margin-bottom: 48px;
     }

    .header__logo-and-desc {
        width: 328px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
     }

     .header__logo {
        width: 160px;
        height: 36px;
    }

    .header__contacts {
       width: 328px;
    }

    .header__button {
        width: 232px;
    }
}