.form-popup {
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility .3s, opacity .3s linear;
}

.form-popup_opened {
    visibility: visible;
    opacity: 1;
}

.form-popup__overlay {
    cursor: pointer;
    outline: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: #000;
    opacity: .5;
    z-index: -1;
}

.form-popup__container {
    background-color: #fff;
    border-radius: 20px;
    padding: 44px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-popup__overlay:hover {
    background-color: #000;
}

.form-popup__container {
    width: 560px;
}

.form-popup__title {
    margin: 8px auto 16px;
}

.form-popup__button {
    width: 160px;
    margin-top: 40px;
}

@media screen and (max-width: 767px) {
    .form-popup__container {
        width: 300px;
    }
}
